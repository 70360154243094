import React, { useState, useEffect } from 'react';
import './Weather.css';
import GenericTitle from '../GenericTitle/GenericTitle';
import Loader from '../Loader/Loader';
import WeatherUnit from './WeatherUnit/WeatherUnit';
import WeatherService from '../../service/WeatherService';
import { weatherCurrentSlide } from '../../hooks/weatherCarrousel';
import { streamWeather } from '../../firebase';

let isLoading = true;
let actualSlide = 1;
let weatherListSplit = [];
let intervalId;
let lastUpdate;
const weatherService = new WeatherService();

function Weather(props) {
  const [weatherList, setWeatherList] = useState([]);

  useEffect(() => {
    const unsubscribe = streamWeather({
      next: (querySnapshot) => {
        isLoading = false;
        if (querySnapshot.data()) {
          lastUpdate = querySnapshot.data().lastUpdate;
          setWeatherList(querySnapshot.data().data);
        } else {
          lastUpdate = new Date();
          setWeatherList([]);
        }

        weatherCurrentSlide(actualSlide);
        if (!intervalId) {
          intervalId = setInterval( () => moveSlide(actualSlide + 1), 20000);
        }
        return () => cleanUpInterval();
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      }
    });
    return unsubscribe;
  }, []);

  weatherListSplit = weatherService.applyConfigurationAndSplit(weatherList, props);

  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  }

  const moveSlide = (slideNum) => {
    if(slideNum > weatherListSplit.length) {
      slideNum = 1;
    }
    actualSlide = slideNum;
    weatherCurrentSlide(slideNum);
  }

  return (
    <div className="weather">
      {!isLoading && (
      <>
        <GenericTitle title={"Weather"} color={"#FEC532"} margin={ "0.5vh 1vh 0.3vh 1vh" } lastUpdate={lastUpdate}/>
        
          {
            weatherListSplit.map((weather, indx) => (
              <WeatherGroup key={indx} weatherGrp={weather}/>
            ))
          }
        
        <div className="carousell-dots">
          {
            weatherListSplit.map((weather, indx) => (
              <span className="weather-dot" key={indx} onClick={ () => moveSlide(indx+1) }></span>
            ))
          }
        </div>
      </>
      )}

      {/* Loader */ isLoading && (<> <Loader id='weather-loader'/> </> )}
    </div>
  );

  function WeatherGroup(props){

    let weatherGrp = [];

    props.weatherGrp.forEach((element, index) => {
      let weather = <WeatherUnit key={index} weatherData={ element }/>
  
      weatherGrp.push(weather);
    });

    return (
      <div className="weather-group weather-mySlides weather-fade">
        { weatherGrp }
      </div>
    );
  }

}

export default Weather;
