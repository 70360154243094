import React, { useEffect } from 'react';
import './VideoWall.css';
import LatamHeader from './components/LatamHeader/LatamHeader';
import LatamNewsBand from './components/LatamNewsBand/LatamNewsBand';
import ContingencyCard from './components/ContingencyCard/ContingencyCard';
import CriticalFlights from './components/CriticalFlights/CriticalFlights';
import CriticalAircraft from './components/CriticalAircraft/CriticalAircraft';
import GroundSupport from './components/GroundSupport/GroundSupport';
import SummaryLimops from './components/SummaryLimops/SummaryLimops';
import Weather from './components/Weather/Weather';
import Otp from './components/Otp/Otp';
import PhoneDialer from './components/PhoneDialer/PhoneDialer';
import { streamUserConfiguration, getDefaultConfiguration } from './firebase';

function VideoWall(props) {
  const [configurationData, setConfigurationData] = React.useState();
  const userEmail = props.user?.email;
  const userCCO = props.userCCO;

  // It's CRITICAL to keep [] as the second argument to useEffect. Otherwise, we'll rack up UUUGE firestore bills due to opening a gazillion connections
  useEffect(() => {
    async function defaultConfig(cco) {
      const config = await getDefaultConfiguration(cco);
      return config;
    }
    const unsubscribe = streamUserConfiguration(userEmail, {
      next: (querySnapshot) => {
        if (querySnapshot.data()) {
          setConfigurationData(querySnapshot.data());
        } else {
          defaultConfig(userCCO).then((data) => { setConfigurationData(data ? data : {}); });
        }
      },
      error: (e) => {
        console.error(e);
      }
    });
    return unsubscribe;
  }, [userEmail, userCCO]);
  return (
    <> {configurationData &&
      <div className="VideoWall">

        <LatamHeader />

        <LatamNewsBand userCCO={userCCO} />

        <ContingencyCard contingencyFlightsConfig={configurationData.contingencyFlightsConfig} dataFiltersConfig={configurationData.dataFiltersConfig} />
        <div className='temporal'>
          <CriticalFlights criticalFlightsConfig={configurationData.criticalFlightsConfig} dataFiltersConfig={configurationData.dataFiltersConfig} />
          <SummaryLimops summaryLimopsConfig={configurationData.summaryLimopsConfig} dataFiltersConfig={configurationData.dataFiltersConfig} />
        </div>
        <CriticalAircraft
          criticalAircraftConfig={configurationData.criticalAircraftConfig}
          criticalAircraftCheckbox={configurationData}
        />


        <div className="layout">
          <GroundSupport groundSupportConfig={configurationData.groundSupportConfig} />
          <Weather weatherConfig={configurationData.weatherConfig} />
        </div>

        <Otp otpConfig={configurationData.otpConfig} userCCO={userCCO} />
        {/** Phone Dialer Modal */} <PhoneDialer userRole={props.userRole} />
      </div>}
    </>
  );
}

export default VideoWall;
