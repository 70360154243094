import React from 'react';
import './GroundTable.css';

function dateParse(date){
  var response;
  if(date != null){
    var dateFormat = new Date(date);
    var day = dateFormat.getDate() > 9 ? dateFormat.getDate(): "0"+dateFormat.getDate();
    var month = dateFormat.toLocaleString("es-ES", { month: "short" }).toUpperCase();
    var time = date.split(" ")[1];
    response = day+"-"+month+"\n"+time.split(":")[0]+":"+time.split(":")[1];
  }
  else{
    response = "-";
  }
  return response;
}
function GroundTable(props) {
  const getIconByState = (state) => {
    let iconClassName = "no-support";
    if (state) {
      const stateUp = state.toUpperCase();
      if (stateUp === "DISPONÍVEL" || stateUp === "YES") {iconClassName = "operant"}
      if (stateUp === "INDISPONÍVEL" || stateUp === "NO") {iconClassName = "inoperative"}
      if (stateUp.includes("RESTRIÇÃO") || stateUp === "RESTRICTED") {iconClassName = "restricted"}
    } 
    return iconClassName;
  }

  return (    
    <div className="gs-table">
      <div className="gs-table-head">
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>ACU</span>
        <span>GPU</span>
        <span>ASU</span>
      </div>
      {
        props.groundSupportList.map((grndSupport, indx) => (
          <div key={grndSupport.base} className="gs-table-row">
            <span>{dateParse(grndSupport.lastUpdate)}</span>
            <span>{grndSupport.base}</span>
            <span className={getIconByState(grndSupport.acu)}>&nbsp;</span>
            <span className={getIconByState(grndSupport.gpu)}>&nbsp;</span>
            <span className={getIconByState(grndSupport.asu)}>&nbsp;</span>
          </div>
        ))
      }     
    </div>  
  );

}

export default GroundTable;