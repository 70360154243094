import React, { useEffect, useState } from 'react';
import GroundGrp from './GroundGrp/GroundGrp';
import './GroundSupport.css';
import checkmark from './checkmark.svg';
import dash from './dash.svg';
import walert from './walert.svg';
import GenericTitle from '../GenericTitle/GenericTitle';
import Loader from '../Loader/Loader';
import GroundSupportService from '../../service/GroundSupportService';
import {gsCurrentSlide} from '../../hooks/gsCarrousel';
import { streamGroundSupport } from '../../firebase';

let actualSlide = 1;
let intervalId;
let isLoading = true;
let lastUpdate;
let groundSupportListSplit = [];
let groundSupportList;

const groundSupportService = new GroundSupportService();

function GroundSupport(props) {
  const [state, setState] = useState({groundSupportList: []});

  useEffect(() => {
    const unsubscribe = streamGroundSupport({
      next: (querySnapshot) => {
        groundSupportList = [];
          if (querySnapshot.data()) {
            groundSupportList = groundSupportList.concat(querySnapshot.data().data);
            lastUpdate = querySnapshot.data().lastUpdate;
          }

        isLoading = false;
        setState({ groundSupportList: groundSupportList, lastUpdate: lastUpdate});
        gsCurrentSlide(actualSlide);        
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      }
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    gsCurrentSlide(actualSlide);
    if (!intervalId) {
      intervalId = setInterval( () => moveSlide(actualSlide + 1), 20000);
    }
    return () => cleanUpInterval();
  }, [props]);

  groundSupportListSplit = groundSupportService.applyConfigurationAndSplit(state.groundSupportList, props);

  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  }

  const moveSlide = (slideNum) => {
    if(slideNum > groundSupportListSplit.length) {
      slideNum = 1;
    }
    actualSlide = slideNum;
    gsCurrentSlide(slideNum);
  }

  return (    
    <div className="ground-support">
      {!isLoading && (
      <>
        <div className="gs-header">
          <GenericTitle title={"Ground Support NB"} color={"#00AF25"} lastUpdate={ state.lastUpdate }/>
            <div className="gs-legend">
              <p className="gs-legend-title">Operant</p>
              <div className="gs-legend-icon">
                <img className="check-img" src={checkmark} alt="error"></img>
              </div>
            </div>

            <div className="gs-legend">
              <p className="gs-legend-title">Inoperative</p>
              <div className="gs-legend-icon">
                <img className="dash-img" src={dash} alt="error"></img>
              </div>
            </div>

            <div className="gs-legend">
              <p className="gs-legend-title">Restricted</p>
              <div className="gs-legend-icon">
                <img className="walert-img" src={walert} alt="error"></img>
              </div>
            </div>
        </div>
        <div className="gs-body">
          {
            groundSupportListSplit.map((grndSupportGrp, indx) => (
              <GroundGrp key={indx} groundSupportGrp={grndSupportGrp}/>
            ))
          }
        </div>
        <div className="gs-foot">
          <div className="gs-carousell-dots">
            {
              groundSupportListSplit.map((grndSupportGrp, indx) => (
                <span className="gs-dot" key={indx} onClick={ () => moveSlide(indx+1) }></span>
              ))
            }
          </div>
        </div>
      </>
      )}
      {/* Loader */ isLoading && (<> <Loader id='gs-loader'/> </> )}
    </div>
  );
}

export default GroundSupport;