let gsSlideIndex = 1;
gsShowSlides(gsSlideIndex);

const gsCurrentSlide = (n) => {
  gsShowSlides(gsSlideIndex = n);
}

function gsShowSlides(n) {
  let i;
  let slides = document.getElementsByClassName("gs-mySlides");
  let dots = document.getElementsByClassName("gs-dot");
  
  if (n > slides.length) {gsSlideIndex = 1}

  if (n < 1) {gsSlideIndex = slides.length}

  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  if(slides[gsSlideIndex-1] !== undefined){
    slides[gsSlideIndex-1].style.display = "block";  
    dots[gsSlideIndex-1].className += " active";
  }
}

// gs-carousell-dots
// gs-dot

// gs-mySlides gs-fade (body)

export { gsCurrentSlide }