let slideIndex = 1;
showSlides(slideIndex);

const currentSlide = (n) => {
  showSlides(slideIndex = n);
}

function showSlides(n) {
  let i;
  let slides = document.getElementsByClassName("mySlides");
  let dots = document.getElementsByClassName("dot");
  
  if (n > slides.length) {slideIndex = 1}

  if (n < 1) {slideIndex = slides.length}

  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  if(slides[slideIndex-1] !== undefined){
    slides[slideIndex-1].style.display = "block";  
    dots[slideIndex-1].className += " active";
  }
}

// critical flght carou

let slSlideIndex = 1;
slShowSlides(slSlideIndex);

const slCurrentSlide = (n) => {
  slShowSlides(slSlideIndex = n);
}

function slShowSlides(n) {
  let i;
  let slSlides = document.getElementsByClassName("sl-mySlides");
  let slDots = document.getElementsByClassName("sl-dot");
  
  if (n > slSlides.length) {slSlideIndex = 1}

  if (n < 1) {slSlideIndex = slSlides.length}

  for (i = 0; i < slSlides.length; i++) {
    slSlides[i].style.display = "none";
  }

  for (i = 0; i < slDots.length; i++) {
    slDots[i].className = slDots[i].className.replace(" active", "");
  }
  if(slSlides[slSlideIndex-1] !== undefined){
    slSlides[slSlideIndex-1].style.display = "block";  
    slDots[slSlideIndex-1].className += " active";
  }
}

//critical air carou

let caSlideIndex = 1;
caShowSlides(caSlideIndex);

const caCurrentSlide = (n) => {
  caShowSlides(caSlideIndex = n);
}

function caShowSlides(n) {
  let i;
  let slides = document.getElementsByClassName("ca-mySlides");
  let dots = document.getElementsByClassName("ca-dot");
  
  if (n > slides.length) {caSlideIndex = 1}

  if (n < 1) {caSlideIndex = slides.length}

  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  if(slides[caSlideIndex-1] !== undefined){
    slides[caSlideIndex-1].style.display = "block";  
    dots[caSlideIndex-1].className += " active";
  }
}

// ca-carousell-dots
// ca-dot

// ca-mySlides ca-fade

export { currentSlide, slCurrentSlide ,caCurrentSlide}