import React, { useCallback, useContext } from 'react';
import ReactDOM from "react-dom";
import './App.css';
import Configuration from './components/Configuration/Configuration';
import VideoWall from './VideoWall';
import GenericDialog from './components/GenericDialog/GenericDialog';
import Login from './components/Login/Login';
import Options from './components/Login/Options/Options';
import {AuthContext} from './providers/AuthProvider';
import OtpVeo from './components/Otp/OtpVeo';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect
} from "react-router-dom";

function App() {
  const history = useHistory();
  const authState = useContext(AuthContext);

  const userConfirmation = useCallback((message, callback) => {
    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(document.getElementById("custom-prompt"));
      callback(answer);
    };

    ReactDOM.render(<GenericDialog cleanUp={cleanUp} open title="You have not saved your settings." desc={"If you don't save the changes, they won't be applied to the video wall. Do you confirm that you want to exit without saving the changes?"} 
    cancelMsg="Return" okMsg="Exit without saving" />, document.getElementById("custom-prompt"));
  }, []);

  const redirectByRole = () => {
    if (authState.role === 'USER ADMIN') {
      return <Redirect to='/configuration'/>;
    } else if (authState.role === 'SUPER USER' || authState.role === 'OTP' || authState.role === 'SUPER USER LIMOPS' ) {
      return <Redirect to='/options'/>;
    } else if (authState.role === 'VIDEOWALL' || authState.role === 'USER') {
      return <Redirect to='/'/>;
    } else if (!authState.initLoad) {
      return <Redirect to='/login'/>;
    } else {
      return null;
    }
  }

  return(
    <Router getUserConfirmation={userConfirmation} history={history}>
        <Switch>          
          <Route exact path="/">
            {authState.user && authState.role !== 'USER ADMIN'? <VideoWall user={authState.user} userRole={authState.role} userCCO={authState.cco}/> : redirectByRole}
          </Route>
          <Route path="/configuration">
            {authState.user && <Configuration user={authState.user} isUserLimops={authState.role === 'SUPER USER LIMOPS'} isUserAdmin={authState.role === 'USER ADMIN'} canEditNews={authState.role === 'SUPER USER' || authState.role === 'VIDEOWALL' || authState.role === 'SUPER USER LIMOPS' } userCCO={authState.cco}/>}
          </Route>
          <Route path="/login">
            {authState.user && authState.role? redirectByRole : <Login/>}
          </Route>
          <Route path="/options">
            {authState.user && authState.role !== 'USER ADMIN' && authState.role !== 'VIDEOWALL' && authState.role !== 'USER' ? <Options/> : redirectByRole}
          </Route>
          <Route path="/otp">
            {authState.user && (authState.role === 'OTP' || authState.role === 'SUPER USER' || authState.role === 'SUPER USER LIMOPS' )? <OtpVeo user={authState.user} userRole={authState.role} userCCO={authState.cco}/> : redirectByRole}
          </Route>
        </Switch>
        <div id="custom-prompt"></div>
        {authState.redirect && window.location.pathname !== "/login" && <Redirect to='/login'/>}
    </Router>
  );
  
}

export default App;
