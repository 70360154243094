import React, {useEffect} from 'react';
import './LatamHeader.css';
import LatamLogo from '../../vector/latam-logo.svg';
import {ZONE_CLOCKS} from '../../utils/Constants';
import {streamTimezone} from '../../firebase';
import moment from 'moment';

let intervalId;
let timezoneList = [];

function LatamHeader() {
  const [state, setState] = React.useState({timezoneList: [], lastUpdate: moment.utc().toISOString()});
  let utcDate = moment.utc();
  let timezone, diffMinutes, date, minutes;

  useEffect(() => {
    const unsubscribe = streamTimezone({
      next: (querySnapshot) => {
        timezoneList = [];
        if (querySnapshot.data()) {
          timezoneList = querySnapshot.data().data;
        }
        setState({timezoneList: timezoneList});
      },
      error: (e) => {
        console.error(e);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!intervalId) {
      intervalId = setInterval( () => updateTime(timezoneList), 1000);
    }
    return () => cleanUpInterval();
  }, []);

  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  }

  const updateTime = (timezoneList) => {
    setState({timezoneList: timezoneList, lastUpdate: moment.utc().toISOString()});
  }

  const getTime = (base) => {
    utcDate = moment().utc();
    timezone = state.timezoneList.find( tz => tz.base === base &&
      utcDate.isSameOrAfter(moment.utc(tz.startDatetimeUTC, "YYYY-MM-DD HH:mm:ss", true)) &&
      utcDate.isSameOrBefore(moment.utc(tz.endDatetimeUTC, "YYYY-MM-DD HH:mm:ss", true).add(1, 'minutes')));
    diffMinutes = timezone ? timezone.diffMinutes : 0;
    date = utcDate.add(diffMinutes, 'minutes');
    minutes = date.get('minutes') < 10 ? '0'+date.get('minutes') : date.get('minutes');
    return date.get('hours') + ':' + minutes;    
  }
  
  return (
    <div className="latam-title">
        <img className="img-left" src={LatamLogo} alt="error"></img>
        {state.timezoneList.length > 0 && ZONE_CLOCKS.map( (value, index) => 
          <div className="my-clock" key={index}>
            <p className="my-clock-title">&nbsp;{value}</p>
            <div className="my-clock-bkgd"> 
              <p className="my-clock-style">{getTime(value)}</p>
            </div>
          </div>
        )}
        <img className="img-right" src={LatamLogo} alt="error"></img>
    </div>
  );  
}

export default LatamHeader;
