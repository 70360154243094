import React, { useState, useEffect } from 'react';
import './CriticalAircraft.css';
import CriticalAircraftService from '../../service/CriticalAircraftService';
import CriticalAircraftGroup from './CriticalAircraftGroup/CriticalAircraftGroup';
import GenericTitle from '../GenericTitle/GenericTitle';
import CriticalAircraftCounter from './CriticalAircraftCounter/CriticalAircraftCounter';
import Loader from '../Loader/Loader';
import { streamCriticalAircrafts, streamCriticalAircraftsDictionaryFlotaSubFlota } from '../../firebase';
import Carousel from 'react-material-ui-carousel';

let isLoading = true;
let lastUpdate;

function CriticalAircraft(props) {
  const [dataPax, setDataPax] = useState([]);
  const [showPax, setShowPax] = useState([]);
  const [showCarga, setShowCarga] = useState([]);
  useEffect(() => {
    const unsubscribe = streamCriticalAircrafts({
      next: (querySnapshot) => {
        let dataPax;
        if (querySnapshot.data()) {
          setShowPax(props.criticalAircraftCheckbox.checkedPax)
          setShowCarga(props.criticalAircraftCheckbox.checkedCarga)
          dataPax = new CriticalAircraftService().applyConfigurationAndSplitPax(
            querySnapshot.data().data,
            props,
            props.criticalAircraftCheckbox.checkedPax,
            props.criticalAircraftCheckbox.checkedCarga);
          lastUpdate = querySnapshot.data().lastUpdate;
        }
        isLoading = false;

        setDataPax(dataPax);
        console.log(dataPax)
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      }
    });

    const flotaSubFlota = streamCriticalAircraftsDictionaryFlotaSubFlota({
      next: (querySnapshot) => {
        // Buscar coincidencias de edades y crear nuevos objetos con la edad y el país correspondiente
        buscarCoincidenciasYCrearNuevoObjeto(dataPax[0], querySnapshot.data().data);
      }
    })
    return [unsubscribe, flotaSubFlota];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  let aogCount = 0;
  let riskCount = 0;
  let contCount = 0;

  // Función para buscar coincidencias de edades y crear nuevos objetos con la edad y el país correspondiente
  function buscarCoincidenciasYCrearNuevoObjeto(pax, flotaSubFlotaList) {
    // Iterar sobre la pax
    if (pax !== undefined) {
      for (let objeto1 of pax) {
        // Iterar sobre la flotaSubFlotaList para buscar coincidencias de edad
        for (let objeto2 of flotaSubFlotaList) {
          if (objeto1.tail === objeto2.FleetRegistrationCode) {
            objeto1.fleet = objeto2.FleetSubType
          }
        }
      }
      return pax
    }
  }
  dataPax.forEach(crtlAircraftGrp => {
    crtlAircraftGrp.forEach(criticalAircft => {
      if (criticalAircft.aircftStatus === "CONT") {
        contCount += 1;
      } else if (criticalAircft.aircftStatus === "RISK") {
        riskCount += 1;
      } else if (criticalAircft.aircftStatus === "AOG") {
        aogCount += 1;
      }
    })
  });

  return (
    <div className="critical-aircraft">
      {!isLoading && (
        <>
          <div className="critical-aircraft-header">
            <GenericTitle title={"Critical Aircraft"} color={"#1C89F0"} lastUpdate={lastUpdate} />

            <div className="ca-counter-grp">
              <CriticalAircraftCounter title={"RISK"} color={"#FFC700"} count={riskCount} />
              <CriticalAircraftCounter title={"CONT"} color={"#FF8A00"} count={contCount} />
              <CriticalAircraftCounter title={"AOG"} color={"#FF7D7D"} count={aogCount} />
            </div>
          </div>

          {(showPax || showCarga) && <div className="critical-aircraft-main pax">
            <div className="ca-table">

              <div className="slider-pax">
                {dataPax.length > 0 ? (
                  <Carousel navButtonsAlwaysInvisible={true} interval={7000} swipe={true}>
                    {
                      dataPax.map((crtlAircraftGrp, indx) => (
                        <CriticalAircraftGroup key={indx} flightGrp={crtlAircraftGrp} />
                      ))
                    }
                  </Carousel>
                ) : <div className="no-information">Sin información</div>}
              </div>
            </div>
          </div>}
        </>
      )}

      {/* Loader */ isLoading && (<> <Loader id='aircft-loader' /> </>)}
    </div>
  );
}

export default CriticalAircraft;