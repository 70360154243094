class GroundSupportService {

  /**
   * Apply user configuration and split into list of 33
   * @param {array} groundSupportList 
   * @param {object} config 
  */
   applyConfigurationAndSplit(groundSupportList, config) {
    let groundSupportListSplit = [];
    const filteredGS = this.filterGS(groundSupportList, config);
    filteredGS.sort((a, b) => a.base.localeCompare(b.base));
    while (filteredGS.length > 0) {
      groundSupportListSplit.push(filteredGS.splice(0, 30));
    }
    return groundSupportListSplit;
  }

  filterGS(groundSupportList, config){
    /** @type {array} */
    const airportsFilter = config.groundSupportConfig?.airportsFilter;

    if (airportsFilter) {
      return groundSupportList.filter(g => {
        return airportsFilter.includes(g.base);
      });
    } else {
      return groundSupportList;
    }    
  }

  /**
   * Split ground support group in 3 arrays
   * @param {array} groundSupportGrp 
  */
  splitGroundSupportGroup(groundSupportGrp) {
    let groundSupportGrpSplit = [];
    while (groundSupportGrp.length > 0) {
      groundSupportGrpSplit.push(groundSupportGrp.splice(0, 10));
    }
    return groundSupportGrpSplit;
  }
  
}
  
export default GroundSupportService;