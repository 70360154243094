import React from 'react';
import GroundTable from '../GroundTable/GroundTable';
import './GroundGrp.css';
import GroundSupportService from '../../../service/GroundSupportService';

const groundSupportService = new GroundSupportService();

function GroundGrp(props) {

  const groundSupportGrpSplit = groundSupportService.splitGroundSupportGroup(props.groundSupportGrp);

  return (

    <div className="gs-mySlides gs-fade">
      {
        groundSupportGrpSplit.map((grndSupportList, indx) => (
          <GroundTable key={indx} groundSupportList={grndSupportList}/>
        ))
      }
    </div>

  );

}

export default GroundGrp;