import React, {useState, useEffect} from 'react';
import 'moment-timezone';
import './AddUsers.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import { USER_ROLES } from '../../../utils/Constants';
import { firestore, streamUsers } from '../../../firebase';
import GenericDialog from '../../GenericDialog/GenericDialog';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
  { id: 'email', label: 'Email' },
  { id: 'role', label: 'Role' },
  { id: 'delete', label: '' }
];

let userList = [];
let filteredUserList = [];

function AddUsers() {
  const [state, setState] = useState({role: '', cco: '', email: '', filterEmail: '', userList: [], page: 0, rowsPerPage: 10, openDel: false});
  const [snackbar, setSnackbar] = useState('');

  useEffect(() => {
    const unsubscribe = streamUsers({
      next: (querySnapshot) => {
        userList = [];
        querySnapshot.forEach((doc) => {
          userList.push({email: doc.id, cco: doc.data().cco, role: doc.data().role});
        });
        filteredUserList = userList;
        setState({role: '', cco: '', email: '', filterEmail: '', userList: userList, page: 0, rowsPerPage: 10, openDel: false});
      },
      error: (e) => {
        console.error(e);
      }
    });
    return unsubscribe;
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  }
  
  const handleFilterChange = (event) => {
    filteredUserList = state.userList.filter(e => e.email.includes(event.target.value));
    setState({ ...state, filterEmail: event.target.value, page: 0 });
  }

  const handleEditRole = async (event, row) => {
    try {
      await firestore.collection("videowall-users").doc(row.email).update({role: event.target.value});
      setSnackbar({ msg: `User ${row.email} role updated from ${row.role} to ${event.target.value} succesfully!`, date: new Date(), severity: "success" }); 
    } catch (error) {
      console.error(error);
      setSnackbar({ msg: "Unexpected error!. Please contact technical support for help", date: new Date(), severity: "error" });     
    }
  }

  const handleOpenDel = (event, row) => {
    setState({ ...state, deleteUser: row.email, openDel: true });
  };

  const handleDelete = async (confirm) => {
    if (confirm) {
      try {
        await firestore.collection("videowall-users").doc(state.deleteUser).delete();
        setSnackbar({ msg: `User ${state.deleteUser} deleted succesfully!`, date: new Date(), severity: "success" });
      } catch (error) {
        console.error(error);
        setSnackbar({ msg: "Unexpected error!. Please contact technical support for help", date: new Date(), severity: "error" });
      }    
    } else {
      setState({ ...state, openDel: false });
    }
  }

  const handleChangePage = (event, newPage) => {
    setState({ ...state, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, page: 0,  rowsPerPage: +event.target.value});
  };

  const saveUsers = async () => {
    try {
      if (state.role !== '' && state.email !== '' && state.cco !== '') {
        if (/^([\w+-.%]+((@lan\.com)|(@latam\.com))+)(,[\w+-.%]+((@lan\.com)|(@latam\.com))+)*(\s)*$/.test(state.email)) {
          const emailList = state.email.trim().split(',');
          const batch = firestore.batch();
          emailList.forEach(email => {batch.set(firestore.collection("videowall-users").doc(email.toLowerCase()), {cco: state.cco, role: state.role})});
          await batch.commit();
          setSnackbar({ msg: "Users saved succesfully!", date: new Date(), severity: "success" });
        } else {
          setSnackbar({ msg: "Error! Email input has invalid format. It should be @latam.com or @lan.com email and comma separated", date: new Date(), severity: "error" });
        }      
      } else {
        setSnackbar({ msg: "Error! Some input fields are missing. Please fill them all", date: new Date(), severity: "error" });
      }      
    } catch (error) {
      console.error(error);
      setSnackbar({ msg: "Unexpected error!. Please contact technical support for help", date: new Date(), severity: "error" });
    }
  }

  return (
      <div className="add-users-conf">
          <div className="df-title">
              <span>Add Users</span>
          </div>

          <div className="main-selects">
            <div className="add-users-selects">
              <FormControl variant="outlined" className={'email-ctrl'}>
                <TextField
                  label="Email (comma separated)"
                  variant="outlined"
                  name="email"
                  InputLabelProps={{className: "email-select"}}
                  InputProps={{className: "email-input"}}
                  value={state.email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl variant="outlined" className={'rol-ctrl'}>
                <TextField
                  select
                  label="Role"
                  variant="outlined"
                  name="role"
                  onChange={handleChange}
                  InputLabelProps={{className: "role-select"}}
                  InputProps={{className: "role-input"}}
                  SelectProps={{
                      value: state.role,
                      inputProps: {className: "role-input"}
                  }}
                >
                {USER_ROLES.map((value, index) => (
                  <MenuItem key={index} classes={{root: 'option'}} value={value}>{value}</MenuItem>      
                ))}
                </TextField>                
              </FormControl>
              <FormControl variant="outlined" className={'cco-ctrl'}>
                <TextField
                  select
                  label="CCO"
                  variant="outlined"
                  name="cco"
                  InputLabelProps={{className: "cco-select"}}
                  InputProps={{className: "cco-input"}}
                  onChange={handleChange}
                  SelectProps={{
                      value: state.cco,
                      inputProps: {className: "cco-input"}
                  }}
                >
                <MenuItem classes={{root: 'option'}} value="BR">BR</MenuItem>
                <MenuItem classes={{root: 'option'}} value="SSC">SSC</MenuItem>
                </TextField>
              </FormControl>
              </div>
              <Button className="add-btn" variant="contained" color="primary" onClick={saveUsers} disableElevation>
                ADD
              </Button>
              <span> </span>
              <ExcelFile filename="UsuariosVideowall" element={  
              <button className="export-btn" variant="contained" color="primary" >
              EXPORT TO EXCEL
              </button>}>
                <ExcelSheet data={userList} name="Leaves">
                    <ExcelColumn label="EMAIL" value="email"/>
                    <ExcelColumn label="CCO" value="cco"/>
                    <ExcelColumn label="ROLE" value="role"/>
                </ExcelSheet>
              </ExcelFile>
          </div>

          <div className="add-users-table">
            <FormControl variant="standard" className={'filter-email-ctrl'}>
              <TextField
                label="Filter by email"
                variant="standard"
                InputLabelProps={{className: "email-select"}}
                InputProps={{className: "email-input"}}
                value={state.filterEmail}
                onChange={handleFilterChange}
              />
            </FormControl>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} classes={{head: 'users-table-cell-head'}} >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUserList.slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                      {columns.map((column) => {
                        const rowValue = row[column.id];
                        return (
                          <TableCell key={column.id} classes={{body: 'users-table-cell-body'}}>
                            {column.id === 'role' ? 
                            <TextField
                              select
                              variant="standard"
                              onChange={(e) => handleEditRole(e, row)}
                              InputLabelProps={{className: "role-select"}}
                              InputProps={{className: "edit-role-input"}}
                              SelectProps={{
                                  value: row.role,
                                  inputProps: {className: "edit-role-input"}
                              }}
                            >
                            {USER_ROLES.map((value, index) => (
                              <MenuItem key={index} classes={{root: 'option'}} value={value}>{value}</MenuItem>      
                            ))}
                            </TextField> 
                            : column.id === 'delete' ? <Tooltip title="Delete User"><IconButton color="inherit" onClick={(e) => handleOpenDel(e, row)} ><DeleteIcon /> </IconButton></Tooltip>
                            : rowValue}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              classes={{caption: "users-table-pagination", selectRoot: "users-table-pagination", select: "users-table-pagination"}}
              count={filteredUserList.length}
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>

          {/* Generic Snackbar */}
          {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg}/> : null}
          {/* Delete Dialog */}
          <GenericDialog open={state.openDel} title="Confirm that you want to delete this user" desc={"\""+state.deleteUser+"\""} cancelMsg="Return" 
          okMsg="Yes, delete user" cleanUp={handleDelete}/>
      </div>      
  );
  
}

export default AddUsers;

