import { firstBy } from "thenby";

class CriticalAircraftService {

  /**
   * Apply user configuration and split into list of five
   * @param {array} aircraftList 
   * @param {object} config 
   */
  applyConfigurationAndSplitPax(aircraftList, config, pax, carga) {
    let filterDataPax
    if (pax) {
      filterDataPax = aircraftList.filter(criticalAircraft =>
        !(['L7', 'UC', 'M3'].includes(criticalAircraft.operator) ||
          (criticalAircraft.operator === 'XL' && criticalAircraft.fleetType === 'WB'))
      );
    }
    let filterDataCarga = [];
    if (carga) {
      filterDataCarga = aircraftList.filter(criticalAircraft =>
      (['L7', 'UC', 'M3'].includes(criticalAircraft.operator) ||
        (criticalAircraft.operator === 'XL' && criticalAircraft.fleetType === 'WB'))
      );
      filterDataCarga.map(criticalAircraft => criticalAircraft.carga = true)
    }
    
    let formatData;
    if (pax === true && carga === true) {
      formatData = filterDataPax.concat(filterDataCarga);
    } else if (pax === true && carga === false) {
      formatData = filterDataPax;
    } else if (pax === false && carga === true) {
      formatData = filterDataCarga;
    } else {
      formatData = [];
    }
    
    const filteredCriticalAircraft = this.filterCriticalAircraft(formatData, config);
    this.applySortConfig(filteredCriticalAircraft, config);
    let crtlAircraftDataSplit = [];
    while (filteredCriticalAircraft.length > 0) {
      crtlAircraftDataSplit.push(filteredCriticalAircraft.splice(0, 10));
    }
    return crtlAircraftDataSplit;
  }

  filterCriticalAircraft(aircraftList, config) {
    // /** @type {array} */
    // const filialOpFilter = config.dataFiltersConfig.filialOpFilter;
    /** @type {array} */
    const excludedFleets = config.criticalAircraftConfig?.excludedFleets ? config.criticalAircraftConfig.excludedFleets : [];
    /** @type {array} */
    const excludedTails = config.criticalAircraftConfig?.excludedTails ? config.criticalAircraftConfig.excludedTails : [];
    /** @type {array} */
    const excludedStatus = config.criticalAircraftConfig?.excludedStatus ? config.criticalAircraftConfig.excludedStatus : [];

    return aircraftList?.filter(a => {
      return !excludedFleets.includes(a.fleet.substring(1, 4))
        && !excludedTails.includes(a.tail)
        && !excludedStatus.includes(a.aircftStatus);
    });
  }

  /**
   * Apply user sort configuration to critical aircraft
   * @param {array} aircraftList 
   * @param {object} config 
   */
  applySortConfig(aircraftList, config) {
    /** @type {array} */
    const alertSort = config.criticalAircraftConfig?.alertSort ? config.criticalAircraftConfig.alertSort : [{ id: 1, name: 'CONT' }, { id: 2, name: 'AOG' }, { id: 3, name: 'RISK' }];
    /** @type {array} */
    const statusSort = config.criticalAircraftConfig?.statusSort ? config.criticalAircraftConfig.statusSort : [];
    // /** @type {string} */
    // const timeSort = config.criticalAircraftConfig.timeSort;
    aircraftList.sort(
      firstBy(function (v1, v2) {
        return alertSort.findIndex(i => i.name === v1.aircftStatus)
          - alertSort.findIndex(i => i.name === v2.aircftStatus);
      })
        .thenBy(function (v1, v2) {
          return statusSort.findIndex(i => i.name === v1.maintStatus)
            - statusSort.findIndex(i => i.name === v2.maintStatus);
        })
        .thenBy(function (v1, v2) {
          return v1.impactFltEtd && v2.impactFltEtd && v1.impactFltEtd < v2.impactFltEtd ? 1 : -1;
        })
    );
  }

}

export default CriticalAircraftService;